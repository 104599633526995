import React from 'react';
import {  Box, Container } from 'theme-ui';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Link } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const NotFoundPage = () => {
  const { t } = useI18next("header");
  return (
    <Layout>
      <SEO title={t("not_found")} />
      <Box sx={styles.bg}>
        <Container>
          <Box sx={styles.container}>
            <Box as={"h1"}>{t("not_found")}</Box>
            <Box as={"p"}>
              {`${t("go_back")} `}<Link to={"/"}>{t("to_home")}</Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

const styles = {
  bg: {
    backgroundColor: '#FFFCF7',
  },
  container: {
    pt: [14],
    pb: [6],
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
}

export const query = graphql`
  query NotFoundQuery($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["index", "header", "common"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
